<template>
  <div>
    <!--  -->
    <el-dialog
      :title="etitle"
      :visible.sync="dialogVisible"
      width="56%"
      top="3%"
      :show-close="false"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog('formRules')"
    >
      <el-form
        :model="formData"
        :rules="formRules"
        ref="formRules"
        label-width="80px"
        label-position="left"
      >
        <div class="auto">
          <div class="dialog-top">
            <div class="left">
              <DFUpload
                ref="DFUpload"
                @success="onUploadSuccess"
                @error="onUploadError"
                @remove="onRemove"
              >
              </DFUpload>
            </div>
            <div class="right">
              <el-row :gutter="20" class="row-bg">
                <el-col :span="12">
                  <el-form-item label="员工姓名" prop="EmplName">
                    <el-input
                      v-model="formData.EmplName"
                      placeholder="请输入员工姓名"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="工号" prop="EmplNo">
                    <el-input
                      v-model="formData.EmplNo"
                      placeholder="请输入工号"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20" class="row-bg">
                <el-col :span="12">
                  <el-form-item label="职位" prop="DutyNo">
                    <el-select
                      @change="onChangeDuty"
                      v-model="formData.DutyNo"
                      placeholder="请选择职位"
                    >
                      <el-option
                        v-for="(item, index) in dutyList"
                        :key="item.DutyNo + index"
                        :label="item.DutyName"
                        :value="item.DutyNo"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="职位级别" prop="LevelGuid">
                    <el-select
                      v-model="formData.LevelGuid"
                      placeholder="请选择职位级别"
                    >
                      <el-option
                        v-for="(item, index) in tempLevelList"
                        :key="item.LevelGuid + index"
                        :label="item.LevelName"
                        :value="item.LevelGuid"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20" class="row-bg">
                <el-col :span="12">
                  <el-form-item label="重置密码" prop="">
                    <el-button type="primary" size="mini" @click="resetEmplPassword">重置手机端密码</el-button>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="dialog-center">
            <div class="tabs">
              <div
                v-for="(item, index) in tabsData"
                :key="index"
                :class="isAction == index ? 'action' : ''"
                @click="onTabs(index)"
              >
                {{ item }}
              </div>
            </div>
            <div class="tabs-chile" v-show="isAction == 0">
              <el-form-item label-width="auto" required>
                <el-row :gutter="20">
                  <el-col :span="8">
                    <el-form-item
                      label="手机号码"
                      label-width="70px"
                      prop="EmplPhone"
                    >
                      <el-input
                        v-model="formData.EmplPhone"
                        placeholder="请输入手机号码"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item
                      label="身份证号"
                      label-width="70px"
                      prop="IDNumber"
                    >
                      <el-input
                        v-model="formData.IDNumber"
                        placeholder="请输入身份证号"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="入职来源" label-width="70px">
                      <el-input
                        v-model="formData.RecruitWay"
                        placeholder="请输入入职来源"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item label-width="auto" required>
                <el-row :gutter="20">
                  <el-col :span="8">
                    <el-form-item label="入职时间" label-width="70px">
                      <el-date-picker
                        value-format="yyyy-MM-dd"
                        v-model="formData.EntryDate"
                        type="date"
                        placeholder="选择日期"
                      ></el-date-picker>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="离职时间" label-width="70px">
                      <el-date-picker
                        value-format="yyyy-MM-dd"
                        v-model="formData.DimissionDate"
                        type="date"
                        placeholder="选择日期"
                      ></el-date-picker>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="介绍人" label-width="70px">
                      <el-select
                        v-model="formData.IntroduceGuid"
                        placeholder="请选择介绍人"
                        filterable
                      >
                        <el-option
                          v-for="(item, index) in introduceSelector"
                          :key="item.EmplGuid + index"
                          :label="item.EmplName"
                          :value="item.EmplGuid"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item label-width="auto" required>
                <el-row :gutter="20">
                  <el-col :span="8">
                    <el-form-item label="部门" label-width="70px">
                      <el-select
                        v-model="formData.DeptGuid"
                        placeholder="请选择部门"
                      >
                        <el-option
                          v-for="(item, index) in deptList"
                          :key="item.DeptGuid + index + '_'"
                          :label="item.DeptName"
                          :value="item.DeptGuid"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="卡号" label-width="70px">
                      <el-input
                        v-model="formData.CardNo"
                        placeholder="请输入卡号"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="限制轮牌" label-width="70px">
                      <el-select
                        v-model="formData.UpRoundType"
                        placeholder="请选择限制轮牌"
                      >
                        <el-option
                          v-for="(item, index) in roundType"
                          :key="item.Value + index"
                          :label="item.Name"
                          :value="item.Value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item label="性别" label-width="70px">
                <el-radio-group v-model="formData.EmplSex">
                  <el-radio :label="0">女</el-radio>
                  <el-radio :label="1">男</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="其他" label-width="70px">
                <el-checkbox
                  v-model="formData.FreeRound"
                  label="上牌但不参与轮牌"
                  :true-label="1"
                  :false-label="0"
                ></el-checkbox>
                <el-checkbox
                  v-model="formData.AtteNoUpRound"
                  label="考勤不自动上牌"
                  :true-label="1"
                  :false-label="0"
                ></el-checkbox>
              </el-form-item>
              <el-form-item label="员工预约" label-width="70px">
                <el-input
                  v-model="formData.TotalReserveCount"
                  placeholder="请输入员工被预约控制变量"
                ></el-input>
              </el-form-item>
              <el-form-item label="数据权限" label-width="70px">
                <div class="row">
                  <el-select v-model="formData.DataLimitMode">
                    <el-option
                      v-for="item in limitModeList"
                      :key="item.Value"
                      :label="item.Name"
                      :value="item.Value"
                    >
                    </el-option>
                  </el-select>

                  <el-input
                    v-model="formData.DataLimitNum"
                    placeholder=""
                    clearable
                    :maxlength="3"
                    :disabled="formData.DataLimitMode == 0 || formData.DataLimitMode > 10"
                  ></el-input>
                </div>
              </el-form-item>
              <el-form-item label="员工介绍" label-width="70px">
                <el-button type="primary" size="mini" @click="onOpenNoteDrawer">编辑</el-button>
              </el-form-item>
              <el-form-item label="备注" label-width="70px">
                <el-input
                  type="textarea"
                  v-model="formData.EmplNote"
                  placeholder="请输入备注"
                ></el-input>
              </el-form-item>
            </div>
            <div class="tabs-chile" v-show="isAction == 1">
              <template>
                <el-row>
                  <el-col :span="24">
                    <el-checkbox
                      border
                      v-model="formData.PhoneSeach"
                      label="员工手机端开通"
                      :true-label="1"
                      :false-label="0"
                    ></el-checkbox>
                  </el-col>
                </el-row>
                <el-row :gutter="20" v-if="formData.PhoneSeach == 1">
                  <el-col :span="12" v-for="item in power" :key="item.PowerNo">
                    <el-checkbox
                      v-model="item.isSelect"
                      :label="item.PowerName"
                    ></el-checkbox>
                  </el-col>
                </el-row>
              </template>
              <!--  -->
              <template>
                <el-row>
                  <el-col :span="24">
                    <el-checkbox
                      border
                      v-model="formData.HideWxapp"
                      label="自助消费开通"
                      :true-label="0"
                      :false-label="1"
                    ></el-checkbox>
                  </el-col>
                </el-row>
                <el-row v-if="formData.HideWxapp == 0">
                  <el-col :span="4">
                    <el-form-item label="员工排序" label-width="70px">
                      <el-input v-model="formData.Sort" size="mini"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </template>
            </div>
          </div>
        </div>
        <div class="dialog-bottom">
          <el-form-item>
            <el-button type="info" @click="onCloseDialog('formRules')"
              >取消</el-button
            >
            <el-button
              type="primary"
              :disabled="submitDiabled"
              @click="submitForm('formRules')"
              >确认</el-button
            >
          </el-form-item>
        </div>
      </el-form>
    </el-dialog>

    <DfQuilEditorDrawer ref="DfQuilEditorDrawer" @onBlurText="onBlurEdit"></DfQuilEditorDrawer>
  </div>
</template>

<script>
import employee from "@/api/employee.js";
import { baseURL } from "@/utils/axios.js";
export default {
  props: {
    etitle: {
      type: String,
      default: "",
    },

    levelList: {
      type: Array,
      default: () => [],
    },

    formData: {
      type: Object,
      default: () => {
        return {};
      },
    },

    deptList: {
      type: Array,
      default: () => [],
    },

    dutyList: {
      type: Array,
      default: () => [],
    },

    power: {
      type: Array,
      default: () => [],
    },

    roundType: {
      type: Array,
      default: () => [],
    },

    introduceSelector: {
      type: Array,
      default: () => [],
    },

    limitModeList: {
      type: Array,
      default: () => [],
    },
    
  },

  data() {
    return {
      isAction: 0,
      tabsData: ["基本资料", "平台互通"],
      formRules: {
        EmplName: [
          { required: true, message: "请输入员工姓名", trigger: "blur" },
        ],
        EmplNo: [{ required: true, message: "请输入工号", trigger: "blur" }],
        DutyNo: [{ required: true, message: "请选择职位", trigger: "change" }],
        EmplPhone: [
          {
            // pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: "请输入正确手机号",
            trigger: "blur",
          },
        ],
        LevelGuid: [
          { required: true, message: "请选择职位级别", trigger: "change" },
        ],
      },
      tempLevelList: [],
      employeeGuid: "",
      dialogVisible: false,
      submitDiabled: false,
    };
  },

  watch: {
    formData: {
      deep: true,
      handler(newVal) {
        // console.log(newVal);
        if (this.formData.DutyNo) {
          let tempArray = [];
          this.levelList.forEach((item) => {
            if (item.DutyNo == this.formData.DutyNo) {
              tempArray.push(item);
            }
          });
          this.tempLevelList = tempArray;
        }

        // console.log(this.formData);
        let fileList = this.$refs.DFUpload ? this.$refs.DFUpload.fileList : [];
        if (
          fileList.length != 0 &&
          fileList[0].url == this.formData.PictureUrl
        ) {
          return;
        }

        let pictureArray =
          this.formData.PictureUrl != ""
            ? [{ url: this.formData.PictureUrl }]
            : [];
        this.$nextTick(() => {
          pictureArray != ""
            ? this.$refs.DFUpload.initImageList(pictureArray)
            : null;
        });
      },
    },
  },

  mounted() {},

  methods: {
    async onUploadSuccess(event) {
      // console.log(event);
      // let imageUrl = await event.data;
      let imageUrl = event ? event.data : "";
      let submitData = { ...this.formData };
      submitData.Picture = imageUrl ? imageUrl : submitData.Picture;
      submitData.Sort = submitData.Sort == "" ? 999 : 0;
      submitData.TotalReserveCount = Number(submitData.TotalReserveCount || 0);

      let res, text;

      // 如果有 EmplGuid 就是修改
      if (await this.formData.EmplGuid) {
        submitData.EmplGuid = this.formData.EmplGuid;
        text = "修改成功!";
      } else {
        text = "添加成功!";
      }

      try {
        res = await employee.saveEmpl(submitData);
        if (res.errcode == 0) {
          this.$message.success(text);
          this.onCloseDialog();
          this.$emit("success");
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.submitDiabled = false;
      }
    },

    resetEmplPassword(){
      console.log(this.formData);
      this.$confirm(`是否重置该员工密码`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        let { data, errcode, errmsg } = await employee.resetEmplPassword({
          empl_guid: this.formData.EmplGuid,
        });
        // console.log(errcode);
        if (errcode == 0) {
          this.$message.success("密码重置成功");
          this.$emit("success");
        }
      });
    },

    onUploadError(event) {
      this.submitDiabled = false;
      console.log(event);
    },

    onOpenNoteDrawer(){
      this.$refs.DfQuilEditorDrawer.onShowDrawer(this.formData.RichDetail)
    },

    onBlurEdit(event){
      this.formData.RichDetail = event
    },

    async onRemove(file) {
      let submitData = await { ...this.formData };
      this.$confirm(`是否删除该图片`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        let { data, errcode, errmsg } = await employee.deleteEmplPricture({
          empl_guid: submitData.EmplGuid,
        });
        // console.log(errcode);
        if (errcode == 0) {
          this.$message.success("头像删除成功");
          this.$refs.DFUpload.onRemove(file);
          this.onCloseDialog();
          this.$emit("success");
        }
      });
    },

    onChangeDuty(event) {
      this.formData.LevelGuid = "";
      this.tempLevelList = this.levelList.filter((item) => {
        return item.DutyNo == event;
      });
    },

    onTabs(index) {
      this.isAction = index;
    },

    submitForm(formName) {
      this.$refs["formRules"].validate(async (valid) => {
        if (valid) {
          try {
            let submitData = await { ...this.formData };
            let temp = [];
            let token = await window.sessionStorage.getItem("token");

            this.submitDiabled = await true;

            await this.power.forEach(async (item) => {
              if (await item.isSelect) {
                await temp.push(item.PowerNo);
              }
            });
            submitData.ListPowerNo = await temp;

            if (submitData.Sort == (await "")) {
              submitData.Sort = await 999;
            }
            this.formData = submitData;
            let actionUrl = (await submitData.Picture)
              ? ""
              : baseURL + `/employee/upload_empl_picture?acctoken=${token}`;
            console.log(actionUrl);
            await this.$refs.DFUpload.submitUpload(actionUrl);
          } catch (e) {
            this.submitDiabled = await false;
          }
        } else {
          return false;
        }
      });
    },

    onShowDialog() {
      this.dialogVisible = true;
    },

    onCloseDialog() {
      this.isAction = 0;
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.auto {
  height: 73vh;
  overflow-y: scroll;
}
.auto::-webkit-scrollbar-track {
  background-color: #ffffff;
}
.auto::-webkit-scrollbar {
  width: 3px;
}
.auto::-webkit-scrollbar-thumb {
  background-color: #999999;
}

.dialog-top {
  display: flex;
  align-items: center;
  min-height: 200px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  padding: 20px 0;
  .left {
    max-width: 470px;
    margin: 0 20px;
  }
  .right {
    .el-form-item:last-child {
      margin-bottom: 0;
    }
  }
}

.dialog-center {
  margin-top: 20px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  .tabs {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 20px;
    border-bottom: 1px solid #dcdfe6;
    box-sizing: border-box;
    margin-bottom: 20px;
    > div {
      display: flex;
      align-items: center;
      height: 40px;
      margin-right: 40px;
      box-sizing: border-box;
      border-bottom: 2px solid transparent;
      transition: all 0.5s;
    }
    .action {
      border-bottom: 2px solid #409eff;
    }
  }
  .tabs-chile {
    padding: 0 20px;
    
    .row{
      .flex-row;

      .el-input, .el-select{
        width: 200px;
        margin-right: 10px;
      }
    }
  }
  .el-select {
    width: 100%;
  }
}

.dialog-bottom {
  margin-top: 20px;
  .el-form-item {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0;
  }
}

.row-bg {
  padding: 15px 0;
}

.el-checkbox {
  margin-bottom: 20px;
}

#el-checkbox {
  margin-bottom: 0;
}

.el-date-editor {
  width: 100%;
}
</style>
