<template>
  <div>
    <el-dialog
      class="deputy-dialog"
      title="员工副职管理"
      :visible.sync="showDeputyDialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog('formRules')"
    >
      <el-form ref="formRules" :model="formData" :rules="formRules">
        <div class="dialog-top-box">
          <el-form-item label="选择副职" prop="DutyNo">
            <el-select
              v-model="formData.DutyNo"
              @change="onDutyChange"
              size="small"
              placeholder="请选择职位"
            >
              <el-option
                v-for="item in dutyList"
                :key="item.DutyNo"
                :label="item.DutyName"
                :value="item.DutyNo"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="选择副职" prop="LevelGuid">
            <el-select
              v-model="formData.LevelGuid"
              size="small"
              placeholder="请选择职位"
            >
              <el-option
                v-for="item in dutyLevelList"
                :key="item.LevelGuid"
                :label="item.LevelName"
                :value="item.LevelGuid"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item class="checkbox-item">
            <el-checkbox v-model="formData.FreeRound">不参与轮牌</el-checkbox>
          </el-form-item>

          <el-form-item class="button-item" label-width="90px">
            <el-button
              ref="submitButton"
              type="primary"
              size="small"
              @click="submitForm('formRules')"
              >保 存 新 增</el-button
            >
          </el-form-item>
        </div>
      </el-form>

      <el-table v-loading="loading" :data="tableData" style="width: 100%">
        <el-table-column prop="DutyName" label="副职名称" width="180">
        </el-table-column>
        <el-table-column prop="LevelName" label="副职级别" width="180">
        </el-table-column>
        <el-table-column label="不参与轮牌">
          <template slot-scope="scope">
            {{ scope.row.FreeRound ? "是" : "否" }}
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="danger"
              size="mini"
              @click="onRemoveDutyData(scope.row.LevelName)"
              >移除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import employee from "@/api/employee";
export default {
  data() {
    return {
      deputyList: [],
      dutyList: [],
      dutyLevelList: [],
      levelList: [],
      tableData: [],
      formData: {
        EmplGuid: "",
        DutyNo: "",
        LevelGuid: "",
        FreeRound: false,
      },
      formRules: {
        DutyNo: [{ required: true, message: "请选择职位", trigger: "change" }],

        LevelGuid: [
          { required: true, message: "请选择级别", trigger: "change" },
        ],
      },
      showDeputyDialog: false,
      loading: true,
    };
  },

  methods: {
    async initPageData() {
      let submitData = {
        empl_guid: this.formData.EmplGuid,
      };
      this.loading = true;

      let { data } = await employee.getEmplViceList(submitData);

      this.tableData = data;
      this.loading = false;
      console.log(this.tableData);
    },

    async initDutyData() {
      let submitData = {
        empl_guid: this.formData.EmplGuid,
      };

      let { data } = await employee.initEmplVice(submitData);

      let { dutyList, levelList } = data;
      this.dutyList = dutyList;
      this.levelList = levelList;
      // console.log(this.levelList);
    },

    onDutyChange(DutyNo) {
      this.dutyLevelList = this.levelList.filter((item) => {
        return item.DutyNo == DutyNo;
      });
    },

    openDeputyDialog(EmplGuid) {
      this.formData.EmplGuid = EmplGuid;
      this.initPageData();
      this.initDutyData();
      this.showDeputyDialog = true;
    },

    onCloseDialog(formName) {
      this.$refs[formName].resetFields();
      this.formData = {
        EmplGuid: "",
        DutyNo: "",
        LevelGuid: "",
        FreeRound: false,
      };
      this.showDeputyDialog = false;
    },

    async onRemoveDutyData(levelName) {
      let submitData = {
        empl_guid: this.formData.EmplGuid,
        level_guid: this.levelList.filter((item) => {
          return item.LevelName == levelName;
        })[0].LevelGuid,
      };
      let { errcode } = await employee.deleteEmplVice(submitData);
      if (errcode == 0) {
        this.$message.success("副职移除成功");
        this.initPageData();
        this.$emit("success");
      }
    },

    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.$refs.submitButton.disabled = true;
          try {
            let { EmplGuid, LevelGuid, FreeRound } = this.formData;
            let submitData = {
              EmplGuid,
              LevelGuid,
              FreeRound: FreeRound ? 1 : 0,
            };
            let { errcode } = await employee.saveEmplVice(submitData);
            if (errcode == 0) {
              this.$message.success("新增副职成功");
              this.initPageData();
              this.$emit("success");
            }
          } finally {
            this.$refs.submitButton.disabled = false;
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep .el-dialog {
  width: 780px;
  // max-width: 60%;
}

.deputy-dialog {
  .dialog-top-box {
    width: 100%;
    height: 60px;
    display: flex;

    > .el-form-item {
      display: flex;
      flex-direction: row;
      // width: 40% !important;
      // flex: 2;
      margin: 0;
      ::v-deep .el-form-item__content {
        width: 160px !important;
      }

      .el-select {
        // width: 50% !important;
        margin-right: 10px;
      }
    }

    .checkbox-item {
      ::v-deep .el-form-item__content {
        width: auto !important;
      }
    }

    .button-item {
      ::v-deep .el-form-item__content {
        width: auto !important;
        margin-left: 60px !important;
      }
    }
  }
}
</style>