<template>
  <div>
    <el-dialog
      title="选择日期"
      :visible.sync="showDialog"
      width="300px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <span class="content-box">
        <div class="df-row">
          <div class="df-col">离职日期：</div>
          <div class="df-col">
            <el-date-picker
              v-model="submitData.dismiss_date"
              size="small"
              type="date"
              align="left"
              unlink-panels
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            >
            </el-date-picker>
          </div>
        </div>
      </span>
      <span slot="footer">
        <el-button @click="onCloseDialog">取 消</el-button>
        <el-button type="primary" @click="dismissEmpl">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Employee from "@/api/employee.js";

export default {
  data() {
    return {
      submitData: {
        empl_guid: "",
        dismiss_date: "",
      },
      showDialog: false,
    };
  },

  mounted() {},

  methods: {
    // 员工离职
    async dismissEmpl() {
      if (!this.submitData.dismiss_date) {
        return this.$message.error("请选择离职日期！");
      }
      try {
        let { errcode } = await Employee.dismissEmpl(this.submitData);
        if (errcode == 0) {
          this.$message.success("员工离职成功！");
          this.$emit("success");
          this.onCloseDialog();
        }
      } catch (err) {
        console.log(err);
      }
    },

    // 打开对话框
    onShowDialog(emplGuid) {
      this.submitData = {
        empl_guid: JSON.parse(JSON.stringify(emplGuid)),
        dismiss_date: this.util.getNowMonthToday(),
      };
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.content-box {
  .df-row {
    .df-col {
      .flex-row;
    }
  }
  .el-date-editor {
    width: 140px;
  }
}
</style>
